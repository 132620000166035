import axios from '@axios'
import { $api } from '@apiConfig'
// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'qs'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // eslint-disable-next-line no-unused-vars
    fetchUsersCount(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${$api.app.user}/count`, {
            params: queryParams,
            paramsSerializer: params => qs.stringify(params, { encode: false }),
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get($api.app.user, {
            params: queryParams,
            paramsSerializer: params => qs.stringify(params, { encode: false }),
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${$api.app.user}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post($api.app.user, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, { id, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${$api.app.user}/${id}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
